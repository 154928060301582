@font-face {
  font-family: "GraphikFont";
  src: url("./fonts/Graphik-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "GraphikFont";
  src: url("./fonts/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  font-family: "GraphikFont";
  height: 100%;
}

.fade-enter {
  z-index: 0;
  opacity: 0;
}
.fade-enter-active {
  z-index: 1;
  opacity: 1;
  transition: opacity 1000ms cubic-bezier(0.6, 0.04, 0.98, 0.635);
}
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms cubic-bezier(0.6, 0.04, 0.98, 0.635);
}
